
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#0c98a5; 						// the brand colour - green
$color-primary-light:	rgba($color-primary, 0.5);		// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#ff6419; 						// the accent colour - orange
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker

$color-tertiary: 		#0a7c86; 						// the accent colour - dark green
$color-tertiary-light:	lighten($color-tertiary, 5%);	// the accent colour - lighter
$color-tertiary-dark:	darken($color-tertiary, 5%);	// the accent colour - darker

$color-quaternary: 		#85ccd2; 						// the accent colour - light green
$color-quaternary-light:lighten($color-quaternary, 5%);	// the accent colour - lighter
$color-quaternary-dark:	darken($color-quaternary, 5%);	// the accent colour - darker

// 

//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eef0ef;						// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#b8c1c2;	 					// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#85898a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black


//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#5f6364;								// body text colour
$color-sub:		$color-text;							// sub and intro text colour
$color-title:	$color-primary;							// heading text colour


