
// Default .button class
.button {
    @extend %button;


    &.small {
        height: $button-height * 0.75;
        line-height: $button-height * 0.75;
        font-size: 0.7rem;
    }

    &.large {
        height: $button-height-large;
        line-height: $button-height-large;
        font-size: 1.1rem;
    }


    &.fill {

        &-brand {
            background: $color-primary;
            color: $color-white;
        }

        &-alt {
            background: $color-secondary;
            color: $color-white;
        }

        &-tone {
            background: $color-white;
            color: $color-secondary;
        }
    }

    &.to-fill {

        &-brand {

            &:hover {
                background: $color-primary;
                color: $color-white;
            }
        }

        &-alt {

            &:hover {
                background: $color-secondary;
                color: $color-white;
            }
        }

        &-tone {

            &:hover {
                background: $color-white;
                color: $color-primary;
            }
        }
    }


    &.outline {

        &-brand {
            border-color: $color-primary;
            color: $color-primary;
        }

        &-alt {
            border-color: $color-secondary;
            color: $color-secondary;
        }

        &-tone {
            border-color: $color-white;
            color: $color-white;
        }
    }

    &.to-outline {

        &-brand {

            &:hover {
                border-color: $color-primary;
                color: $color-primary;
            }
        }

        &-alt {

            &:hover {
                border-color: $color-secondary;
                color: $color-secondary;
            }
        }

        &-tone {

            &:hover {
                border-color: $color-white;
                color: $color-white;
            }
        }
    }


    a {
        color: inherit;
        transition: inherit;
    }
}
