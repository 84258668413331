
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    display: inline-block;
    height: $button-height;
    line-height: $button-height;
    padding: 0 ($block-padding * 1.5);
    border: $border-size $border-style transparent;
    @include if( $border-radius, ( border-radius: $border-radius ) );
    font-size: 0.8rem;
    text-align: center;
    @extend %sub-title;
    transition: all t(link) ease-out;
}

%hover {
    background: $color-secondary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;
    padding: 0 ($block-padding * 2/3);

    &:hover {
        @extend %hover;
    }
}

